import * as React from "react"
import {Link} from "gatsby"

const Layout = ({location, title, children}) => {
    const rootPath = `${__PATH_PREFIX__}/`
    const isRootPath = location.pathname === rootPath
    let header

    if (isRootPath) {
        header = (
            <h1 className="main-heading">
                <Link to="/">{title}</Link>
            </h1>
        )
    } else {
        header = (
            <Link className="header-link-home" to="/">
                {title}
            </Link>
        )
    }

    return (
        <div className="global-wrapper" data-is-root-path={isRootPath}>
            <header className="global-header">{header}</header>
            <main>{children}</main>
            <footer>
                <div>
                    ©{new Date().getFullYear()}, Built with
                    {` `}
                    <a href="https://www.gatsbyjs.com">Gatsby</a>
                </div>
                <div>
                    <a href="http://beian.miit.gov.cn/">津ICP备2021005061号-1</a>
                </div>
                <div>
                    不良信息反馈入口：<a href="mailto:s7dqz4wsck@privaterelay.appleid.com">Sun's Email</a>
                </div>
            </footer>
        </div>
    )
}

export default Layout
